/* Arts Section Layout */

.about-section{
    display: flex;
}


.about-img{
    width: 12em;
    height: auto;
}

.break-xs-md::before {
    content: "";
    display: none;
}


/* Media Queries */
@media screen and (min-width: 375px) {
    .about-img{
        width: 15em;
    }
    
}

@media screen and (min-width: 425px){
    .about-img{
        width: 17em;
    }  
}

@media screen and (min-width: 768px){
    .about-img{
        width: 15em;
    }
}

@media screen and (min-width: 1000px){
    .about-img{
        width: 20em;
    }
    
}

@media screen and (min-width: 1200px){
    .about-img{
        width: 25em;
    }
}
  
@media screen and (max-width: 767px) {
.break-xs-md::before {
    content: "";
    display: block;
}
}
  

/* Hero layout */
#hero{
    color: white;
    height: 100vh;
}
  
.text-overlay{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/* Hero content */
.hero-title{
    font-family: "Cinzel Decorative", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.5em !important;
}


.hero-subtitle{
    font-family: "Livvic", sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 0.8em;
}

.hero-title, .hero-subtitle{
    user-select: none;
    cursor: default;
}

.hero-icons{
    font-size: 1.5em;
}


/* Media Queries */

/* Larger Phones */
@media screen and (min-width: 375px) and (max-width: 768px){
    .hero-title{
        font-size: 3em !important;
    }
    .hero-subtitle{
        font-size: 1em;
    }
    .hero-icons{
        font-size: 1.8em;
    } 
}

/* Tablets */
@media screen and (min-width: 768px) and (max-width: 1024px){
    .hero-title{
        font-size: 3.5em !important;
    }
    .hero-subtitle{
        font-size: 1.3em;
    }
    .hero-icons{
        font-size: 2em;
    }
    
}

/* Larger Screens */
@media screen and (min-width: 1024px){
    .hero-title{
        font-size: 4.5em !important;
    }
    .hero-subtitle{
        font-size: 1.7em;
    }
    .hero-icons{
        font-size: 2.2em;
    }
    
}

/* XXL screens */
@media screen and (min-width: 1400px){
    .hero-title{
        font-size: 5.5em !important;
    }
    .hero-subtitle{
        font-size: 1.9em;
    }
    .hero-icons{
        font-size: 2.5em;
    }
    
}

/* Greater than XXL screens */
@media screen and (min-width: 2000px){
    .hero-title{
        font-size: 12em !important;
    }
    .hero-subtitle{
        font-size: 4em;
    }
    .hero-icons{
        font-size: 5em;
    }
    
}

main{
    position: relative;
}


/* Media Queries */
@media screen and (min-width: 450px){
    .section-container{
        top: 10%;
    }
}
/* Global styling */

.btn{
    border: none !important;
    padding: 0;
}

.primary-button, .primary-button:hover, .primary-button:focus{
    background-color: #7B1F27 !important;
}

.icons{
    color: white;
}

a{
    text-decoration: none !important;
    color: inherit !important;
}

.section-container{
    top: 2%;
    background-color: #000000;
    color: white;
    font-family: "Livvic", sans-serif;
    margin-bottom: 10em;
    margin-top: 5em;
}

.footer-container{
    position: relative;
    bottom: 0;
    right: 0;
}
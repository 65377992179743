/* Arts Section Layout */

.arts-section{
    display: flex;
}


.art-cover {
    width: 12em;
    height: 12em;
    object-fit: cover;
}



/* Media Queries */
@media screen and (min-width: 375px) and (max-width:424px){  /* Medium phones */
    .art-cover{
        width: 15em;
        height: 15em;
    }
    
}

@media screen and (min-width: 425px) and (max-width: 767px){  /* Large phones */
    .art-cover{
        width: 18em;
        height: 18em;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 1300px){  /* Tablets */
    .art-cover{
        width: 14em;
        height: 14em;
    }
}

@media screen and (min-width: 1300px){  /* Bigger screens */
    .art-cover{
        width: 18em;
        height: 18em;
    }
    
}

/* Books Section Layout */

.best-selling-container{
    background-color: #483F48;
    display: flex;
}

.best-selling-cover-img{
    width: 10em;
    height: auto;
}

.rating-stars{
    color: #FFB600;
}

.book-cover{
    width: 8em;
    height: auto;
}

.featured-books-container{
    display: flex;
}


/* Media Queries */
@media screen and (min-width: 375px) and (max-width:425px){
    .best-selling-cover-img{
        width: 13em;
    }
    
}

@media screen and (min-width: 425px) and (max-width: 760px){
    .best-selling-cover-img{
        width: 15.5em;
    }
    
}

@media screen and (min-width: 768px){
    .best-selling-cover-img{
        width: 12em;
    }
    
}

.scrollToTopBtn.hide{
    display: none !important;
}

.scrollToTopBtn.show{
    display: block;
    width: 3em;
    height: 3em;
    background-color: rgba(255, 255, 255, 0.788) !important;
    color: black !important;
    bottom: 50px;
    left: 80vw;
    position: fixed;
    z-index: 1;
}

.scrollToTopBtn.show > .scrollToTopIcon{
    font-size: 1.7em;
    
}


@media screen and (min-width: 768px) {
    .scrollToTopBtn.show{
        left: 85vw;
        width: 3.5em;
        height: 3.5em;
    }

    .scrollToTopBtn.show > .scrollToTopIcon{
        font-size: 2em;
        
    }
    
}







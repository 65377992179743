/* Navigation */

.navbar{
    background-color: #000000;
    color: #ffffff;
}

.navbar-brand img {
    width: 2em;
    height: auto;
}

.navbar .nav-link{
    font-weight: 600;
    margin-left: 1.5em;
}

.navbar .navbar-toggler{
    border: none;
    padding: 0;
}

.navbar .navbar-toggler:focus{
    box-shadow: none;
}


/* Hamburger button */
.navbar .navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='1.2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* Closing button */
.navbar .navbar-toggler-icon.open {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='white'%3e%3cpath stroke='none' stroke-linecap='round' stroke-miterlimit='10' stroke-width='1.5' d='M4.707 4.293a1 1 0 0 1 1.414 0L15 12.586l8.293-8.293a1 1 0 0 1 1.414 1.414L16.414 14l8.293 8.293a1 1 0 0 1-1.414 1.414L15 15.414l-8.293 8.293a1 1 0 0 1-1.414-1.414L13.586 14 4.707 5.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
}


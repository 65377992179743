.app-footer{
    background-color: black;
    color: white;
}

.footer-profile-img{
    width: 4em;
    height: auto;
}

.social-media-icons{
    font-size: 2.2em;
}

.contact-icons{
    font-size: 1.2em;
    margin-right: 0.3em;
}

.footer-copyrights{
    margin-top: 6em !important;
}


/* Media Queries */
@media  screen and (max-width: 374px) {
    .social-media-icons{
        font-size: 1.9em;
    }
}

@media  screen and (min-width: 475px) {
    .row{
        justify-content: space-around;
    }
}

